@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ant-btn:hover{
  background-color: #BFDBF7 !important ;
  color:#1F7A8C !important;
}

.anticon{
  color: #1F7A8C !important;
}

input :hover{
  border-color: #1F7A8C !important;
}


.ant-dropdown .ant-dropdown-menu{
  box-shadow: inset 0 2px 8px 2px;
}

.ant-input-outlined{
  background-color: transparent !important;
}

.ant-menu-item-icon{
  color: #1F7A8C !important;
}

@layer utilities {
  .scrollbar-thin {
    scrollbar-width: thin;
  }
  
  .scrollbar-hide::-webkit-scrollbar {
    display: none; 
  }

  .scrollbar-custom::-webkit-scrollbar {
    width: 2px; 
  }

  .scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4); 
    border-radius: 9999px; 
  }
  
  .scrollbar-custom::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }
}


.sidebar-search svg{
  margin: auto;
}

.sideBar-menu svg{
  color: white !important;
}
body{
  overflow-x: hidden;
}

.leaflet-top {
  top: 100px !important;
}

.ant-dropdown .ant-dropdown-menu {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
}

.z-500 {
  z-index: 500;
}